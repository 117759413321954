import styled from "styled-components";

export const ErrorContainer = styled.div`
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #e55353;
    color: #FFF;

    .error-header {
        text-align: center;

        h1 {
            margin-bottom: 10px;
        }
    }

    button.btn-refresh {
        border: none;
        background-color: #FFF;
        border-radius: 5px;
        padding: 10px;
        font-size: 16px;
        margin-top: 10px;
    }
`;