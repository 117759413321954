import React, { useEffect } from "react";
import { ErrorContainer } from "./style";
import { useHistory } from "react-router-dom";

const ErrorBoundaryFallback: React.FC<any> = ({ error, resetErrorBoundary }) => {
    const history = useHistory();

    return (
        <ErrorContainer>
            <div className="error-header">
                <h1 className="error-title">Ops, ocorreu um erro nessa tela!</h1>
                <h2 className="error-subtitle">Tente novamente mais tarde ou reinicie a aplicação.</h2>
            </div>

            <button
                className="btn-refresh"
                onClick={() => {
                    history.push("/home");
                    window.location.reload();
                }}
            >
                Reiniciar
            </button>
        </ErrorContainer>
    );
};

export default ErrorBoundaryFallback;