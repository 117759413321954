import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CHeader,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useHistory } from "react-router-dom";
import krebsLogo from "../../../assets/images/logo-krebs-parking.png";
import { ErrorText, Header } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, ErrorMessage } from "formik";
import * as Yup from "yup";
import api from "src/services/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import calkLogo from "../../../assets/images/calk.png";
import Loading from "src/components/Loading";

const SendEmail = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string().email().required("Campo obrigatório"),
    /* password: Yup.string().required("Campo obrigatório"),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Senhas difierentes').required("Campo obrigatório"), */
  });

  async function handleSendEmail(dt: any) {
    setLoading(true);
    const validationErrors = {};

    try {
      await schema.validate(dt, { abortEarly: false });

      const { data } = await api.post(`/reset/password/${dt.email}`);

      toast("Enviamos um link para recuperação de senha no email cadastrado!", {
        type: "success",
        theme: "dark",
      });
      /* toast("Senha alterada com sucesso!", {
        type: "success",
        theme: "dark",
      }); */
    } catch (error) {
      console.log("ERROR > ", error);
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
      } else if (error.response.status >= 400 && error.response.status < 500) {
        toast("Erro ao alterar senha!", {
          type: "error",
          theme: "dark",
        });
      }
    }
    setLoading(false);
  }

  /*
   email: "adm@krebs.com.br",
      password: "krebs1320",
  */

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: handleSendEmail,
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <ToastContainer />

      <Loading open={loading} close={() => setLoading(false)} />

      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <Header>
                    <img src={krebsLogo} />
                    <img src={calkLogo} style={{ marginLeft: 20 }} />
                  </Header>
                  <CForm onSubmit={formik.handleSubmit}>
                    <p className="text-muted">Informe o email cadastrado</p>
                    <CInputGroup
                      className="mb-3"
                      style={{ flexDirection: "column" }}
                    >
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-lock-locked" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          name="email"
                          type="email"
                          placeholder="Email"
                          autoComplete="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                        />
                      </div>
                      {formik.errors.email && (
                        <ErrorText>{`${formik.errors.email}`}</ErrorText>
                      )}
                    </CInputGroup>
                    {/* <CInputGroup
                      className="mb-4"
                      style={{ flexDirection: "column" }}
                    >
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-lock-locked" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          type="password"
                          name="confirmPassword"
                          placeholder="Confirme a Senha"
                          autoComplete="current-password"
                          onChange={formik.handleChange}
                          value={formik.values.confirmPassword}
                        />
                      </div>
                      {formik.errors.confirmPassword && (
                        <ErrorText>{`${formik.errors.confirmPassword}`}</ErrorText>
                      )}
                    </CInputGroup> */}
                    <CRow>
                      <CCol xs="6">
                        <CButton type="submit" color="primary" className="px-4">
                          Recuperar senha
                        </CButton>
                      </CCol>
                      {/* <CCol xs="6" className="text-right">
                        <CButton color="link" className="px-0">Forgot password?</CButton>
                      </CCol> */}
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              {/* <CCard className="text-white bg-primary py-5 d-md-down-none" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua.</p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>Register Now!</CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard> */}
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default SendEmail;
