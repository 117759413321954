import styled from "styled-components";

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export const ErrorText = styled.span`
  font-size: 12px;
  color: red;
`;
