import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "@krebs",
  storage,
};

const initialState = {
  sidebarShow: "responsive",
  asideShow: false,
  darkMode: false,
  authenticated: false,
  pivo: {},
  orcamento: null,
  historico: null,
  pivoHeader: {},
  action: "",
  codeNew: null,
  approveEnable: false,
  credentials: {
    token: null,
    user: null,
  },
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const persistedReducer = persistReducer(persistConfig, changeState);

const store = createStore(persistedReducer);
const persistor = persistStore(store);
export { store, persistor };
