import axios from "axios";

let apiHost = "";

switch (process.env.REACT_APP_ENV) {
  case "development": {
    // apiHost = "https://apicalk.krebs.homolog.focalweb.com.br";
    apiHost = "https://api.calk.krebs.com.br";
    break;
  }
  case "localhost": {
    apiHost = "http://localhost:3333";
    break;
  }
  case "production": {
    apiHost = "https://api.calk.krebs.com.br";
    break;
  }
}

// console.log('ENV', process.env.REACT_APP_ENV)
// console.log('API HOST', apiHost)

const api = axios.create({
  //baseURL: "http://localhost:8080",
  baseURL: apiHost,
});

export default api;
